import React, { useState, useEffect } from 'react';
import siteService from '../../services/siteService';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import Modal from 'react-modal';
import styles from './Sites.module.scss'; // Importera SCSS-modulen
import Select from 'react-select'; // Import react-select for all inputs

// Initialiserar react-modal
Modal.setAppElement('#root');

const Sites = () => {
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [verticals, setVerticals] = useState([]);
  const [regions, setRegions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingSite, setEditingSite] = useState(null);
  const [siteForm, setSiteForm] = useState({
    url: '',
    logo: '',
    name: '',
    status: 'Active',
    verticalId: '',
    regionId: '',
  });

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      borderRadius: '8px',
      padding: '2px',
      minHeight: '42px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#86b7fe',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6c757d',
    }),
  };

  // Hämta alla sidor, verticals och regions vid mount
  useEffect(() => {
    fetchSites();
    fetchVerticals();
    fetchRegions();
  }, []);

  const handleSiteChange = (selectedOption) => {
    setSelectedSite(selectedOption ? selectedOption.value : null);
  };

  // Hämta sidor från API
  const fetchSites = async () => {
    try {
      const response = await siteService.getUserSites();
      setSites(response);
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  // Hämta verticals från API
  const fetchVerticals = async () => {
    try {
      const response = await siteService.fetchVerticals();
      setVerticals(response);
    } catch (error) {
      console.error('Error fetching verticals:', error);
    }
  };

  // Hämta regions från API
  const fetchRegions = async () => {
    try {
      const response = await siteService.fetchRegions();
      setRegions(response);
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  };

  // Hantera inmatning i formuläret
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSiteForm({ ...siteForm, [name]: value });
  };

  // Öppna modalen för att lägga till/redigera en site
  const openModal = (site = null) => {
    if (site) {
      setEditingSite(site);
      setSiteForm({
        url: site.url,
        logo: site.logo,
        name: site.name,
        status: site.status,
        verticalId: site.verticalId || '',
        regionId: site.regionId || '',
      });
    } else {
      setEditingSite(null);
      setSiteForm({
        url: '',
        logo: '',
        name: '',
        status: 'Active',
        verticalId: '',
        regionId: '',
      });
    }
    setShowModal(true);
  };

  // Stäng modalen
  const closeModal = () => {
    setShowModal(false);
    setEditingSite(null);
    setSiteForm({
      url: '',
      logo: '',
      name: '',
      status: 'Active',
      verticalId: '',
      regionId: '',
    });
  };

  // Hantera form-submit för att skapa/redigera en site
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingSite) {
        // Uppdatera en befintlig site
        await siteService.editSite(editingSite.id, siteForm);
      } else {
        // Skapa en ny site
        await siteService.createUserSites(siteForm);
      }
      fetchSites();
      closeModal();
    } catch (error) {
      console.error('Error saving site:', error);
    }
  };

  return (
    <div className={styles.siteContainer}>
      <div className={styles.header}>
      <h1>Sites</h1>
      </div>
      <div className={styles.options}>
      <div className={styles.selectSite}>
              <label>Select site</label>
              <Select
                name="site"
                value={sites.find(option => option.value === selectedSite) || null}
                onChange={handleSiteChange}
                options={(sites.map(site => ({ value: site.id, label: site.name })))}
                styles={customSelectStyles}
                placeholder="Select site"
              />
            </div>
      <Button variant="primary" onClick={() => openModal()} className={styles.addButton}>
        Add New Site
      </Button>
    </div>

  <div className={styles.cardContainer}>
  {sites.map((site) => (
      <Card className={styles.card}>
            <Card.Img
              src="https://media.istockphoto.com/id/1147544807/sv/vektor/ingen-miniatyr-bild-vektor-grafik.jpg?s=612x612&w=0&k=20&c=RWp5ECPYkIEO8J3zxyvsc4Lrf68NiLDybtPHix_QI1I="
              alt="logo"
              className={styles.cardImg}
            />
            <div className={styles.cardInfo}>
            <div>
              <div className={styles.cardUrl}><strong>URL:</strong> {site.url}</div>
              <div className={styles.cardStatus}><strong>Status:</strong> {site.status}</div>
            </div>
            <Button
              variant="warning"
              onClick={() => openModal(site)}
              className={styles.editButton}
            >
              Edit
            </Button>
            </div>
      </Card>
  ))}
  </div>


      {/* Modal för att skapa/redigera site */}
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel={editingSite ? 'Edit Site' : 'Add New Site'}
      >
        <h2>{editingSite ? 'Edit Site' : 'Add New Site'}</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUrl">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              name="url"
              value={siteForm.url}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formLogo">
            <Form.Label>Logo</Form.Label>
            <Form.Control
              type="text"
              name="logo"
              value={siteForm.logo}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={siteForm.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              name="status"
              value={siteForm.status}
              onChange={handleInputChange}
              required
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formVertical">
            <Form.Label>Vertical</Form.Label>
            <Form.Control
              as="select"
              name="verticalId"
              value={siteForm.verticalId}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Vertical</option>
              {verticals.map((vertical) => (
                <option key={vertical.id} value={vertical.id}>
                  {vertical.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formRegion">
            <Form.Label>Region</Form.Label>
            <Form.Control
              as="select"
              name="regionId"
              value={siteForm.regionId}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Region</option>
              {regions.map((region) => (
                <option key={region.id} value={region.id}>
                  {region.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit" className={styles.button}>
            {editingSite ? 'Update Site' : 'Create Site'}
          </Button>
          <Button variant="secondary" onClick={closeModal} className={styles.button}>
            Cancel
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default Sites;
