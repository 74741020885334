import React, { useState, useEffect } from 'react';
import authService from '../../services/authService';
import styles from './FormStyles.module.scss';
import { Link } from 'react-router-dom'; 
import LoadingButtonDots from '../../components/LoadingButtonDots'; // Importera LoadingButtonDots-komponenten

const ForgotPassword = () => {
    useEffect(() => {
        document.title = 'Forgot Password';
      }, []);  
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Lägg till en state för att hantera laddningsindikatorn

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Sätt loading till true när vi börjar processen
        try {
            const response = await authService.forgotPassword(email);
            setMessage(response.message || 'Password reset link has been sent to your email.');
            setError(''); // Rensa eventuella tidigare felmeddelanden
        } catch (error) {
            setError(error.response && error.response.data.message ? error.response.data.message : 'Failed to send reset link. Please try again.');
            setMessage(''); // Rensa meddelandet om det finns ett fel
        } finally {
            setLoading(false); // Sätt loading till false när processen är klar
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (error) {
            setError(''); // Rensa felmeddelandet när användaren börjar skriva
        }
    };

    return (
        <div className={styles.container}>
            <h1>Giraffe</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <h2>Forgot Password</h2>
                <div className={styles.inputs}>
                    <div className={styles.emailInput}>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            className={`${styles.input} ${error ? styles.errorInput : ''}`}
                            placeholder="Email"
                        />
                        {error && <p className={styles.errorMessage}>{error}</p>}
                    </div>
                    <button type="submit" className={styles.button} disabled={loading}>
                        {loading ? <LoadingButtonDots /> : 'Send Reset Link'}
                    </button>
                </div>
                <div className={styles.registerLoginLink}>
                    <Link to="../login">Back to login</Link>
                </div>
                {message && <p className={styles.resetMessage}>{message}</p>}
            </form>
        </div>
    );
};

export default ForgotPassword;
