// LoadingButtonDots.js
import React from 'react';
import styles from './LoadingButtonDots.module.scss';

const LoadingButtonDots = () => {
    return (
        <div className={styles.dots}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default LoadingButtonDots;
