import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate, Outlet } from 'react-router-dom';
import styles from './UserLayout.module.scss';
import authService from '../services/authService';

const UserLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Start with sidebar open
  const [showDropDown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropDown);
  };

  const logout = async () => {
    await authService.logout();
    navigate('/login');
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropDown]);

  return (
    <div className={styles.container}>
      <div className={`${styles.sidebar} ${sidebarOpen ? styles.open : styles.closed}`}>
        <div className={styles.header}>
          <h1>G<span>iraffe</span></h1>
        </div>
        <div className={styles.sideMenu}>
          <ul>
            <li className={styles.sideMenuItem}>
              <NavLink to="/dashboard" className={({ isActive }) => isActive ? styles.active : undefined} onClick={handleLinkClick}>
                <span className="material-symbols-outlined">dashboard</span>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
                      <NavLink
            to="/sites"
            className={({ isActive }) =>
              isActive || window.location.pathname === '/sites' ? styles.active : undefined
            }
            onClick={handleLinkClick}
          >
<span class="material-symbols-outlined">
select_window
</span>            <p>Sites</p>
          </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
                      <NavLink
            to="/programs"
            className={({ isActive }) =>
              isActive || window.location.pathname === '/programs' ? styles.active : undefined
            }
            onClick={handleLinkClick}
          >
<span class="material-symbols-outlined">
dataset
</span>           <p>Programs</p>
          </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
                      <NavLink
            to="/performance"
            className={({ isActive }) =>
              isActive || window.location.pathname === '/performance' ? styles.active : undefined
            }
            onClick={handleLinkClick}
          >
<span class="material-symbols-outlined">
signal_cellular_alt
</span>           <p>Performance</p>
          </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
                      <NavLink
            to="/earnings"
            className={({ isActive }) =>
              isActive || window.location.pathname === '/earnings' ? styles.active : undefined
            }
            onClick={handleLinkClick}
          >
<span class="material-symbols-outlined">
payments
</span>            <p>Earnings</p>
          </NavLink>
            </li>
          </ul>
        </div>
        <div className={styles.toggleSidebar} onClick={toggleSidebar}>
          <span className="material-symbols-outlined">
            chevron_left
          </span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.topnav}>
          <button className={styles.hamburger} onClick={toggleSidebar}>
            <span className="material-symbols-outlined">menu</span>
          </button>
          <ul className={styles.topMenu}>
            <li className={`${styles.topMenuItem} ${styles.support}`}>
              <NavLink to="/support" className={({ isActive }) => isActive ? styles.active : undefined}>
                <span className="material-symbols-outlined">support_agent</span>
                <p>Support</p>
              </NavLink>
            </li>
            <li className={styles.topMenuItem}>
              <button className={styles.profile} onClick={toggleDropdown}>
                <span className="material-symbols-outlined">person</span>
                <p>Profile</p>
              </button>
              <div ref={dropdownRef} className={`${styles.dropdown} ${showDropDown ? styles.dropdownOpen : ''}`}>
                <div className={styles.dropdownItem}>
                  <NavLink to="/settings">
                    <span className="material-symbols-outlined">settings</span>
                    Settings
                  </NavLink>    
                </div>

                <div className={styles.dropdownItem}>
                  <button onClick={logout}>
                    <span className="material-symbols-outlined">logout</span>
                    Logout
                  </button>
                </div>

              </div>
            </li>
          </ul>
        </div>
        <div className={styles.main}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
