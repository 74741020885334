import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import UserLayout from './layout/UserLayout';
import AdminLayout from './layout/AdminLayout';

import PrivateRoute from './security/privateRoute';
import PublicRoute from './security/publicRoute';
import StandardRoute from './security/standardRoute';

// ADMIN
import ProgramRequests from './pages/admin/ProgramRequests'

// USER
import Sites from './pages/user/Sites';
import Programs from './pages/user/Programs';
import Performance from './pages/user/Performance';

// SHARED
import Login from './pages/shared/Login';
import ForgotPassword from './pages/shared/ForgotPassword';
import ResetPassword from './pages/shared/ResetPassword';
import Register from './pages/shared/Register';
import Unauthorized from './pages/shared/Unauthorized';
import NotFound from './pages/shared/NotFound';

const App = () => {

  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StandardRoute />}>
          <Route path="/" element={<UserLayout />}>
          <Route path="sites" element={<PrivateRoute requiredRole="User"><Sites /></PrivateRoute>} />
          <Route path="programs" element={<PrivateRoute requiredRole="User"><Programs /></PrivateRoute>} />
          <Route path="performance" element={<PrivateRoute requiredRole="User"><Performance /></PrivateRoute>} />
          </Route>
        </Route>

        <Route path="/admin" element={<PrivateRoute requiredRole="Admin"><AdminLayout /></PrivateRoute>}>
            <Route path="programs/requests" element={<PrivateRoute requiredRole="Admin"><ProgramRequests /></PrivateRoute>} />
        </Route>

        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path="/reset-password/:token" element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
