import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import Modal from 'react-modal'; // Using react-modal
import adminService from './../../services/adminService';
import styles from './ProgramRequests.module.scss'; // Import SCSS for custom styles

Modal.setAppElement('#root'); // Required for react-modal accessibility

const ProgramRequests = () => {
  const [programRequests, setProgramRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null); // For modal
  const [showModal, setShowModal] = useState(false);

  // State for commission-related inputs
  const [revshare, setRevshare] = useState(0);
  const [baseline, setBaseline] = useState(0);
  const [cpa, setCpa] = useState(0);
  const [revshareCommissionPercentage, setRevshareCommissionPercentage] = useState(0);
  const [cpaCommissionPercentage, setCpaCommissionPercentage] = useState(0);
  const [affiliateLink, setAffiliateLink] = useState('');

  const fetchProgramRequests = async () => {
    try {
      const data = await adminService.fetchProgramRequests();
      setProgramRequests(data);
    } catch (error) {
      console.error('Error fetching program requests:', error);
    }
  };

  useEffect(() => {
    fetchProgramRequests();
  }, []);

  const openModal = (request) => {
    setSelectedRequest(request);
    
    // Initialize the values for the form inputs based on the request data
    setRevshare(request.Program.standardRevshare || 0);
    setBaseline(request.Program.standardBaseline || 0);
    setCpa(request.Program.standardCPA || 0);
  
    // Calculate percentage removed for Revshare and round to 2 decimal places
    setRevshareCommissionPercentage(
      request.Program.revshareBeforeCommission ? 
        ((1 - (request.Program.standardRevshare || 0) / request.Program.revshareBeforeCommission) * 100).toFixed(2) : 0
    );
  
    // Calculate percentage removed for CPA and round to 2 decimal places
    setCpaCommissionPercentage(
      request.Program.cpaBeforeCommission ? 
        ((1 - (request.Program.standardCPA || 0) / request.Program.cpaBeforeCommission) * 100).toFixed(2) : 0
    );
  
    setShowModal(true);
  };
  

  const closeModal = () => {
    setShowModal(false);
    setSelectedRequest(null);
  };

// Function to handle changes to the revshare input
const handleRevshareChange = (value) => {
    setRevshare(value);
    if (selectedRequest && selectedRequest.Program.revshareBeforeCommission) {
      const beforeCommission = selectedRequest.Program.revshareBeforeCommission;
      const removedPercentage = ((beforeCommission - value) / beforeCommission) * 100;
      setRevshareCommissionPercentage(removedPercentage.toFixed(2)); // Round to 2 decimals
    }
  };
  
  // Function to handle changes to the CPA input
  const handleCpaChange = (value) => {
    setCpa(value);
    if (selectedRequest && selectedRequest.Program.cpaBeforeCommission) {
      const beforeCommission = selectedRequest.Program.cpaBeforeCommission;
      const removedPercentage = ((beforeCommission - value) / beforeCommission) * 100;
      setCpaCommissionPercentage(removedPercentage.toFixed(2)); // Round to 2 decimals
    }
  };
  
  // Function to handle changes to the revshare commission percentage
  const handleRevshareCommissionChange = (value) => {
    setRevshareCommissionPercentage(value);
    if (selectedRequest && selectedRequest.Program.revshareBeforeCommission) {
      const beforeCommission = selectedRequest.Program.revshareBeforeCommission;
      const newRevshare = beforeCommission - (value / 100) * beforeCommission;
      setRevshare(newRevshare.toFixed(2)); // Round to 2 decimals
    }
  };
  
  // Function to handle changes to the CPA commission percentage
  const handleCpaCommissionChange = (value) => {
    setCpaCommissionPercentage(value);
    if (selectedRequest && selectedRequest.Program.cpaBeforeCommission) {
      const beforeCommission = selectedRequest.Program.cpaBeforeCommission;
      const newCpa = beforeCommission - (value / 100) * beforeCommission;
      setCpa(newCpa.toFixed(2)); // Round to 2 decimals
    }
  };
  
  // Handle accept program request
  const handleAccept = async () => {
    console.log(selectedRequest)
    if (selectedRequest) {
      try {
        await adminService.acceptProgramRequest(selectedRequest.Site.id, selectedRequest.Program.id, {
          revshare,
          cpa,
          baseline,
          affiliateLink
        });
        fetchProgramRequests(); // Refresh requests list after accept
        closeModal(); // Close modal after successful accept
      } catch (error) {
        console.error('Error accepting program request:', error);
      }
    }
  };

  // Handle deny program request
  const handleDeny = async () => {
    if (selectedRequest) {
      try {
        await adminService.denyProgramRequest(selectedRequest.Site.id, selectedRequest.Program.id);
        fetchProgramRequests(); // Refresh requests list after deny
        closeModal(); // Close modal after successful deny
      } catch (error) {
        console.error('Error denying program request:', error);
      }
    }
  };
  
  return (
    <div className={styles.programRequestsContainer}>
      <div className={styles.cardGrid}>
        {programRequests.map((request) => (
          <Card key={request.id} className={styles.programRequestCard}>
            <Card.Body>
              <p className={styles.requestDate}>{new Date(request.createdAt).toLocaleDateString()}</p>
              <div className={styles.programRequestContent}>
                {/* Site section */}
                <div className={styles.siteInfo}>
                  <img
                    src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                    alt={request.Site.url}
                    className={styles.logo}
                  />
                  <p>{request.Site.url}</p>
                </div>

                {/* Arrow between Site and Program */}
                <div className={styles.arrow}>
                  <span className="material-symbols-outlined">arrow_forward</span>
                </div>

                {/* Program section */}
                <div className={styles.programInfo}>
                  <img
                    src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                    alt={request.Program.name}
                    className={styles.logo}
                  />
                  <p>{request.Program.name}</p>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => openModal(request)}
                    className={styles.respondButton}
                  >
                    Respond
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Modal for showing more details and updating commission */}
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContent}>
          {selectedRequest && (
            <>
              <div className={styles.siteProgramInfo}>
                <div className={styles.firstInfo}>
                <p className={styles.infoLabel}>Site</p>
                <div className={styles.imageText}>
                <img
                    src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                    className={styles.logo}
                  />
                  <div className={styles.mainInfo}>
                  <p>{selectedRequest.Site.name}</p>
                  <p>{selectedRequest.Site.url}</p>
                  </div>
                </div>
                </div>
                <div className={styles.firstInfo}>
                <p className={styles.infoLabel}>Program</p>
                <div className={styles.imageText}>
                <img
                    src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                    className={styles.logo}
                  />
                  <div className={styles.mainInfo}>
                  <p>{selectedRequest.Program.name}</p>
                  <p>{selectedRequest.Program.status}</p>
                  </div>
                  </div>
                  <div className={styles.standardDeal}>
                  <p><strong>Standard CPA: </strong>{selectedRequest.Program.standardCPA || "-"}</p>
                  <p><strong>Your CPA: </strong>{selectedRequest.Program.cpaBeforeCommission || "-"}</p>
                  <p><strong>Standard Revenue share: </strong> {selectedRequest.Program.standardRevshare || "-"}</p>
                  <p><strong>Your Revenue share: </strong> {selectedRequest.Program.revshareBeforeCommission || "-"}</p>
                  <p><strong>Standard baseline: </strong>{selectedRequest.Program.standardBaseline || "-"}</p>
                  </div>
                </div>
              </div>

            <div className={styles.inputContainer}>
            <Form>
            <Form.Group controlId="formAffiliateLink" className={styles.affiliateLink}>
                  <Form.Label>Affiliate Link</Form.Label>
                  <Form.Control
                    type="text"
                    value={affiliateLink}
                    onChange={(e) => setAffiliateLink(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formRevshare">
                  <Form.Label>Revenue Share</Form.Label>
                  <Form.Control
                    type="number"
                    value={revshare}
                    onChange={(e) => handleRevshareChange(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formRevsharePercentage">
                  <Form.Label>Rev. Commision %</Form.Label>
                  <Form.Control
                    type="number"
                    value={revshareCommissionPercentage}
                    onChange={(e) => handleRevshareCommissionChange(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formCpa">
                  <Form.Label>CPA</Form.Label>
                  <Form.Control
                    type="number"
                    value={cpa}
                    onChange={(e) => handleCpaChange(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formCpaPercentage">
                  <Form.Label>CPA Commission %</Form.Label>
                  <Form.Control
                    type="number"
                    value={cpaCommissionPercentage}
                    onChange={(e) => handleCpaCommissionChange(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBaseline">
                  <Form.Label>Baseline</Form.Label>
                  <Form.Control
                    type="number"
                    value={baseline}
                    onChange={(e) => setBaseline(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>
            </>
          )}
           <div className={styles.buttonGroup}>
           <Button variant="primary" className={styles.acceptButton} onClick={handleAccept}>Accept</Button>
          <div className={styles.rightButtonGroup}>
          <Button variant="danger" className={styles.denyButton} onClick={handleDeny}>Deny</Button>
          <Button variant="secondary" className={styles.closeButton} onClick={closeModal}>Close</Button>
          </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProgramRequests;
