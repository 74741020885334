import React, { useState, useEffect } from 'react';
import programService from '../../services/programService';
import siteService from '../../services/siteService';
import { Button, Table } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Select from 'react-select'; // Import react-select for all inputs
import styles from './Programs.module.scss';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ProgramsPage = () => {
  const [programs, setPrograms] = useState([]);
  const [sites, setSites] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [status, setStatus] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [linkStatus, setLinkStatus] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [programToJoin, setProgramToJoin] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSiteToJoin, setSelectedSiteToJoin] = useState(null);

  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    if (selectedSite) {
      fetchBrands(selectedSite.id);
      fetchPrograms(selectedSite.id);
    }
  }, [selectedSite, status, brandId, linkStatus]);

  const fetchSites = async () => {
    try {
      const response = await siteService.getUserSites();
      setSites(response);
      setSelectedSite(response[0] || null); // Automatically select the first site
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  const fetchBrands = async (siteId) => {
    try {
      const response = await programService.fetchBrands(siteId);
      setBrands(response.map(brand => ({ value: brand.id, label: brand.name })));
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const fetchPrograms = async (siteId) => {
    try {
      const response = await programService.getSitePrograms(siteId, { status, brandId, linkStatus });
      setPrograms(response);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const handleSiteChange = (selectedOption) => {
    const site = sites.find(site => site.id === selectedOption.value);
    setSelectedSite(site);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption ? selectedOption.value : null);
  };

  const handleBrandChange = (selectedOption) => {
    setBrandId(selectedOption ? selectedOption.value : null);
  };

  const handleLinkStatusChange = (selectedOption) => {
    setLinkStatus(selectedOption ? selectedOption.value : null);
  };

  const handleRowClick = (programId) => {
    const isRowCurrentlyExpanded = expandedRows.includes(programId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? expandedRows.filter(id => id !== programId)
      : [...expandedRows, programId];
    setExpandedRows(newExpandedRows);
  };

  const isRowExpanded = (programId) => {
    return expandedRows.includes(programId);
  };

  const getDealValue = (siteDeal, standardDeal, field) => {
    return siteDeal && siteDeal[field] !== null ? siteDeal[field] : standardDeal[field];
  };

  const joinProgramModal = (program) => {
    setProgramToJoin(program);
    setSelectedSiteToJoin(selectedSite); // Use the full site object for the modal
    setShowModal(true);
  };

  const joinProgram = async (siteId, programId) => {
    try {
      await programService.joinProgram(siteId, programId);
      fetchPrograms(siteId);
      setShowModal(false);
    } catch (error) {
      console.error('Error joining program:', error);
    }
  };

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const linkStatusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Not Joined', label: 'Not Joined' },
  ];

  // Custom select styles for react-select
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      borderRadius: '8px',
      padding: '2px',
      minHeight: '42px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#86b7fe',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6c757d',
    }),
  };

  return (
    <div className={styles.programContainer}>
      <div className={styles.header}>
        <h1>Programs</h1>
        <p>Select the Site and Deal type you want below to see all Programs available for you to market. Click the Program to see details and get your links!</p>
      </div>
      <div className={styles.filterForm}>
        <div>
          <label>Select site</label>
          <Select
            name="site"
            value={selectedSite ? { value: selectedSite.id, label: selectedSite.name } : null}
            onChange={handleSiteChange}
            options={sites.map(site => ({ value: site.id, label: site.name }))}
            styles={customSelectStyles}
            placeholder="Select site"
          />
        </div>

        <div>
          <label>Status</label>
          <Select
            name="status"
            value={statusOptions.find(option => option.value === status) || null}
            onChange={handleStatusChange}
            options={statusOptions}
            isClearable
            styles={customSelectStyles}
            placeholder="Select status"
          />
        </div>

        <div>
          <label>Brand</label>
          <Select
            name="brandId"
            value={brands.find(option => option.value === brandId) || null}
            onChange={handleBrandChange}
            options={brands}
            isClearable
            placeholder="Select a brand"
            styles={customSelectStyles}
          />
        </div>

        <div>
          <label>Links</label>
          <Select
            name="linkStatus"
            value={linkStatusOptions.find(option => option.value === linkStatus) || null}
            onChange={handleLinkStatusChange}
            options={linkStatusOptions}
            styles={customSelectStyles}
            placeholder="Select link status"
            isClearable
          />
        </div>
      </div>

      <Table striped bordered hover className={`mt-4 ${styles.tableFullWidth}`}>
        <thead>
          <tr>
            <th>Program</th>
            <th>Status</th>
            <th>Link Status</th>
            <th>Deal Overview</th>
            <th>Baseline</th>
            <th>Commission %</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {programs.map((program) => {
            const linkStatus = program.siteLinks?.linkStatus || "Not Joined";
            const CPA = getDealValue(program.siteDeal, program.standardDeal, 'CPA') || "-";
            const revShare = getDealValue(program.siteDeal, program.standardDeal, 'revShare') || "-";
            const baseline = getDealValue(program.siteDeal, program.standardDeal, 'baseline') || "-";
            const commissionPercentage = program.siteDeal?.commissionPercentage || "-";

            return (
              <React.Fragment key={program.id}>
                <tr className={isRowExpanded(program.id) && styles.dropdownedtr}>
                  <td className={styles.programTd}>
                    <img src="https://media.istockphoto.com/id/1147544807/sv/vektor/ingen-miniatyr-bild-vektor-grafik.jpg?s=612x612&w=0&k=20&c=RWp5ECPYkIEO8J3zxyvsc4Lrf68NiLDybtPHix_QI1I=" alt="Program" />
                    {program.name}
                  </td>
                  <td>{program.status}</td>
                  <td>{linkStatus}</td>
                  <td>{`${CPA} / ${revShare}`}</td>
                  <td>{baseline}</td>
                  <td>{commissionPercentage}</td>
                  <td onClick={() => handleRowClick(program.id)}>
                    {isRowExpanded(program.id) ? <FaChevronUp /> : <FaChevronDown />}
                  </td>
                </tr>
                {isRowExpanded(program.id) && (
                  <tr className={styles.expandedTr}>
                    <td colSpan="7">
                      <div className={styles.expandedRowContent}>
                        {linkStatus === "Not Joined" ? (
                          <Button variant="primary" onClick={() => joinProgramModal(program)}>Join Program</Button>
                        ) : linkStatus === "Pending" ? (
                          <span>Your request is pending...</span>
                        ) : (
                          <span>Affiliate Link: <a href={program.siteLinks?.affiliateLink}>{program.siteLinks?.affiliateLink}</a></span>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>

      {/* Modal to join program */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Request to join program</h2>
        <div>
          <p>Site</p>
          {selectedSiteToJoin && (
            <>
              <img src={selectedSiteToJoin.img || 'default-site-image-url'} alt="Site" />
              <p>{selectedSiteToJoin.name}</p> {/* Adjusted to use `name` instead of `label` */}
            </>
          )}
        </div>
        <div>
          <p>Program</p>
          {programToJoin && (
            <>
              <img src={programToJoin.img || 'default-program-image-url'} alt="Program" />
              <p>{programToJoin.name}</p>
            </>
          )}
        </div>
        <div>
          <p>Standard deal</p>
          {programToJoin && programToJoin.standardDeal && (
            <>
              <p><strong>CPA:</strong> {programToJoin.standardDeal.CPA}</p>
              <p><strong>Revenue share:</strong> {programToJoin.standardDeal.revShare}</p>
              <p><strong>Baseline:</strong> {programToJoin.standardDeal.baseline}</p>
            </>
          )}
        </div>
        <Button
          variant="primary"
          onClick={() => joinProgram(selectedSiteToJoin.id, programToJoin.id)}
        >
          Join
        </Button>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal>
    </div>
  );
};

export default ProgramsPage;
