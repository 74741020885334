import React, { useState, useEffect } from 'react';
import styles from './Performance.module.scss'; // Importera SCSS-modulen
import programService from '../../services/programService';
import siteService from '../../services/siteService';
import Select from 'react-select'; // Import react-select for all inputs
import { Table } from 'react-bootstrap'; // Import Bootstrap Table

const Performance = () => {
    const [sites, setSites] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);
    const [brandId, setBrandId] = useState(null);
    const [performanceData, setPerformanceData] = useState([]);

    const customSelectStyles = {
        control: (provided) => ({
          ...provided,
          border: '1px solid #ced4da',
          borderRadius: '8px',
          padding: '2px',
          minHeight: '42px',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#86b7fe',
          },
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#6c757d',
        }),
    };

    // Fetch sites and brands on load
    useEffect(() => {
        fetchPerformance()
        fetchSites();
    }, []);

    // Fetch brands and performance data when site or brand changes
    useEffect(() => {
        if (selectedSite) {
            fetchBrands(selectedSite.id);
            fetchPerformance(selectedSite.id, brandId);
        } else {
            setBrands([]);  // Clear brands if no site is selected
            setPerformanceData([]);  // Clear performance data if no site is selected
        }
    }, [selectedSite, brandId]);

    const fetchSites = async () => {
        try {
            const response = await siteService.getUserSites();
            setSites(response);
        } catch (error) {
            console.error('Error fetching sites:', error);
        }
    };

    const fetchBrands = async (siteId) => {
        try {
            const response = await programService.fetchBrands(siteId);
            setBrands(response.map(brand => ({ value: brand.id, label: brand.name })));
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    const fetchPerformance = async (siteId, brandId) => {
        try {
            const response = await programService.fetchPerformance(siteId, brandId);
            setPerformanceData(response);
        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    const handleSiteChange = (selectedOption) => {
        if (selectedOption) {
            const site = sites.find(site => site.id === selectedOption.value);
            setSelectedSite(site);
        } else {
            setSelectedSite(null);  // Clear site selection
        }
    };

    const handleBrandChange = (selectedOption) => {
        setBrandId(selectedOption ? selectedOption.value : null);
    };

    return (
        <div className={styles.performanceContainer}>
            <div className={styles.header}>
                <h1>Performance</h1>
            </div>
            <div className={styles.filterForm}>
                <div>
                    <label>Select site</label>
                    <Select
                        name="site"
                        value={selectedSite ? { value: selectedSite.id, label: selectedSite.name } : null}
                        onChange={handleSiteChange}
                        options={sites.map(site => ({ value: site.id, label: site.name }))}
                        styles={customSelectStyles}
                        placeholder="Select site"
                        isClearable
                    />
                </div>
                <div>
                    <label>Brand</label>
                    <Select
                        name="brandId"
                        value={brands.find(option => option.value === brandId) || null}
                        onChange={handleBrandChange}
                        options={brands}
                        isClearable
                        placeholder="Select a brand"
                        styles={customSelectStyles}
                    />
                </div>
            </div>

            {/* Performance table */}
            <Table striped bordered hover className={`mt-4 ${styles.tableFullWidth}`}>
                <thead>
                    <tr>
                        <th>Program</th>
                        <th>Site</th>
                        <th>Clicks</th>
                        <th>Signups</th>
                        <th>FTD</th>
                        <th>CPA</th>
                        <th>CPA Income</th>
                        <th>Revenue Share Income</th>
                        <th>Total Commission</th>
                    </tr>
                </thead>
                <tbody>
                    {performanceData.length > 0 ? (
                        performanceData.map((data, index) => (
                            <tr key={index}>
                                <td>{data.Program.name}</td>
                                <td>{data.Site.name}</td>
                                <td>{data.weeklyClicks}</td>
                                <td>{data.weeklySignups}</td>
                                <td>{data.weeklyFTD}</td>
                                <td>{data.weeklyCPA}</td>
                                <td>{data.weeklyCPAIncome}</td>
                                <td>{data.weeklyRevShareIncome}</td>
                                <td>{data.weeklyTotalCommission}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="9" className="text-center">No performance data available</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default Performance;
