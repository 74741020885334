import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL + 'sites/';

// Hämta alla sidor för en användare
const getUserSites = async () => {
  try {
    const response = await axios.get(API_URL, {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar datan från svaret
  } catch (error) {
    console.error('Error fetching user sites:', error);
    throw error;
  }
};

// Skapa en ny sida (site)
const createUserSites = async (siteData) => {
  try {
    const response = await axios.post(API_URL, siteData, {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar den skapade siten
  } catch (error) {
    console.error('Error creating new site:', error);
    throw error;
  }
};

// Redigera en befintlig sida (site)
const editSite = async (siteId, siteData) => {
  try {
    const response = await axios.put(`${API_URL}${siteId}`, siteData, {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar den uppdaterade siten
  } catch (error) {
    console.error('Error editing site:', error);
    throw error;
  }
};

const fetchVerticals = async () => {
  try {
    const response = await axios.get(API_URL + "verticals", {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar datan från svaret
  } catch (error) {
    console.error('Error fetching verticals:', error);
    throw error;
  }
};

const fetchRegions = async () => {
  try {
    const response = await axios.get(API_URL + "regions", {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar datan från svaret
  } catch (error) {
    console.error('Error fetching regions:', error);
    throw error;
  }
};

// Exportera alla funktioner
export default {
  fetchRegions,
  fetchVerticals,
  getUserSites,
  createUserSites,
  editSite,
};
