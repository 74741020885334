import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import authService from '../services/authService';

const PrivateRoute = ({ children, requiredRole, requiredNotExpired }) => {
    const [authenticated, setAuthenticated] = useState(null);
    const [role, setRole] = useState(null);
    const [isAuthChecked, setIsAuthChecked] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const auth = await authService.authenticate();
            setAuthenticated(auth.authenticated);
            setRole(auth.role);
            setIsAuthChecked(true);
        };

        checkAuth();
    }, []);

    if (!isAuthChecked) {
        return null; // Du kan visa en laddningsindikator här om du vill
    }

    if (!authenticated) {
        return <Navigate to="/login" />;
    }

    if (requiredRole && role !== requiredRole) {
        return <Navigate to="/unauthorized" />;
    }

    if (requiredNotExpired) { // Kontrollera om expired session krävs
        return <Navigate to="/unauthorized" />;
    }

    return children;
};

export default PrivateRoute;
