import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import authService from '../services/authService';

const PublicRoute = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(null);    
    
    useEffect(() => {
        const fetchAuthentication = async () => {
            try {
                const auth = await authService.authenticate();
                setAuthenticated(auth.authenticated);
            } catch (error) {
                console.error('Error fetching authentication status:', error);
                setAuthenticated(false); // Antag att användaren inte är autentiserad om något går fel
            }
        };

        fetchAuthentication();
    }, []);

    if (authenticated === null) {
        return null; // Du kan lägga till en laddningsindikator här om du vill
    }

    return authenticated ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;
