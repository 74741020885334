import React, { useState } from 'react';
import { NavLink, useNavigate, Outlet, useLocation } from 'react-router-dom';
import styles from './AdminLayout.module.scss';
import authService from '../services/authService';

const AdminLayout = () => {
    const navigate = useNavigate();
    const [showProgramDropdown, setShowProgramDropdown] = useState(false);
    const location = useLocation();

    const logout = async () => {
        await authService.logout();
        navigate('/login');
    };

    const isProgramActive = location.pathname.includes('/admin/programs');


    return (
        <div className={styles.container}>
            <div className={styles.topnav}>
                <div className={styles.topnavComponent}>
                    <div className={styles.header}>
                        <h1>GIRAFFE</h1>
                        <h2>ADMIN</h2>
                    </div>
                    <div className={styles.navLinks}>
                        <NavLink>Users</NavLink>
                        <NavLink>Sites</NavLink>
                        <div
                            className={`${styles.dropdown} ${isProgramActive ? styles.active : ''}`}
                            onMouseEnter={() => setShowProgramDropdown(true)}
                            onMouseLeave={() => setShowProgramDropdown(false)}
                        >
                            <div>Programs</div>
                            {showProgramDropdown && (
                                <div className={styles.dropdownContent}>
                                    <NavLink to="/admin/programs/requests" className={({ isActive }) => isActive ? styles.underline : ''}>Requests</NavLink>
                                </div>
                            )}
                        </div>
                        <NavLink>Reports</NavLink>
                    </div>
                </div>
                <button onClick={logout} className={styles.logout}>
                    <span className="material-symbols-outlined">logout</span>
                    Logout
                </button>
            </div>
            <div className={styles.main}>
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout;
