import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch program requests
const fetchProgramRequests = async () => {
  try {
    const response = await axios.get(`${API_URL}programs/program-requests`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching program requests:', error);
    throw error;
  }
};

// Accept program request
const acceptProgramRequest = async (siteId, programId, updatedData) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/accept-program-request/`, {
      siteId,
      programId,
      updatedData
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error accepting program request:', error);
    throw error;
  }
};

// Deny program request
const denyProgramRequest = async (siteId, programId) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/deny-program-request/`,
      {siteId, programId},
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error denying program request:', error);
    throw error;
  }
};

export default {
  fetchProgramRequests,
  acceptProgramRequest,
  denyProgramRequest,
};
