import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL + 'programs/';

const getSitePrograms = async (siteId, filters) => {
  try {
    const params = { siteId, ...filters };
    const response = await axios.get(API_URL, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching site programs:', error);
    throw error;
  }
};

const fetchBrands = async (siteId) => {
  try {
    const response = await axios.get(`${API_URL}get-brands/${siteId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching programs brands:', error);
    throw error;
  }
}

const joinProgram = async (siteId, programId) => {
  try {
    const response = await axios.post(`${API_URL}join-program-request/`, {siteId, programId}, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error('Error requesting program join:', error);
    throw error;
  }
}

const fetchPerformance = async (siteId, brandId) => {
  try {
    const params = { siteId, brandId };
    const response = await axios.get(`${API_URL}performance`, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching performance data:', error);
    throw error;
  }
};


export default {
  fetchPerformance,
  joinProgram,
  fetchBrands,
  getSitePrograms,
};
